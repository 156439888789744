import { ref, onMounted } from 'vue'
import { getLocalStorage } from '@shein/common-function'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import { cartTagTip, useCartNum } from '@shein-aidc/bs-sdk-cart-tag-tip'
import { useProxyHosts } from '@shein-aidc/bs-sdk-libs-manager'

export const useCartTagTip = ({ isNewCartEntrance }) => {
  const autoUseCoupon = ref(false)

  const { update: updateCartNum } = useCartNum()

  const getAbt = async () => {
    try {
      const { Cartshowcoupon } = await getUserAbtData()
      autoUseCoupon.value = Cartshowcoupon?.param?.cart_show_coupon_switch === 'on'
    } catch (error) {
      // ...
    }
  }
  
  const initialize = () => {
    cartTagTip.initialize({
      disabledFirstTimeTip: true,
      disabledScroolEventListener: true,
      disabledCache: true,
      disabledTagTip: !isNewCartEntrance,
      autoUseCoupon: autoUseCoupon.value,
      getCountryId() {
        const addressCookie = getLocalStorage('addressCookie')
        let countryId = ''
        try {
          const addressJson = typeof addressCookie === 'string' ?  JSON.parse(addressCookie) : addressCookie
          countryId = addressJson.countryId
        } catch (err) {
          //
        }
        return countryId
      },
    })
  }

  const updateCartTagTip = (config) => {
    cartTagTip.update(config)
  }

  const updateCartData = () => {
    useProxyHosts().CommonHeader?.trigger('updateCart')
  }

  const initCartModule = () => {
    window.cart_module = {
      // eslint-disable-next-line no-unused-vars
      reload(type, callback) {
        if (isNewCartEntrance) {
          updateCartTagTip({
            needUpdateCartNum: true,
          })
        } else {
          cartTagTip.updateCartNum()
        }

        updateCartData()
      },
      // eslint-disable-next-line no-unused-vars
      recart(res, notShowMiniCart = false) {
        if (res.code == 0) {
          let cartInfo = res.info
          // 部分加车场景，res 多了一个 info 层级
          if (cartInfo?.info) cartInfo = cartInfo.info

          if (isNewCartEntrance) {
            updateCartTagTip({
              needUpdateCartNum: true,
              cartInfo: cartInfo,
            })
          } else {
            updateCartNum({ num: cartInfo.cartSumQuantity })
          }

          updateCartData()
        }
      },
    }
  }

  onMounted(async () => {
    initCartModule()

    await getAbt()

    initialize()
  })

  return {
    autoUseCoupon,
    isNewCartEntrance,
  }
}
