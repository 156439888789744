<!-- eslint-disable vue/v-on-event-hyphenation -->
<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <div :class="['bsc-search-box', searchClass]">
    <DefaultSearchBox 
      ref="defaultSearchBox"
      :isBigSearch="isBigSearch"
      :lang="lang"
      :siteUID="SiteUID"
      :language="language"
      :isCorrectionRule="abtSearchWordsRef.correctionRule === 'type=B'"
      :associationListNew="associationListNew"
      :marketCallback="marketCallback"
      :associationNewpresearchlenovo="associationNewpresearchlenovo"
      :associationNewpresearchicon="associationNewpresearchicon"
      :associationHKTagOn="associationHKTagOn"
      :associationTagTotal="associationTagTotalRef"
      :associationAttrTotal="associationAttrTotal"
      :getTrackData="getTrackData"
      @inputFocus="handleInputFocus"
      @exposeAssoc="handleExposeAssocDebounce"
      @expose_search="handleExposeSearch"
      @slide_expose_search="handleSlideExposeSearch"
      @clearCurSearchWord="handleClearCurSearchWord"
      @shadedWordDefaultTagOn="handleShadedWordDefaultTagOn"
    />
  </div>
</template>

<script name="BSDefaultSearchBox" setup>
import schttp from 'public/src/services/schttp'
import { debounce } from '@shein/common-function'
import { onMounted, ref, computed, nextTick } from 'vue'
import { getQueryString } from '@shein/common-function'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import { DefaultSearchBox } from '@shein-aidc/bs-search-box-laptop'
import { formatSearchModuleAbtParam } from 'public/src/pages/common/search_words/formatSearchWordAbt.js'
import analysis from './analysis/index'

const props = defineProps({
  language: {
    type: Object,
    default: () => ({})
  },
  isEnlargeSearchPro: {
    type: Boolean,
    default: false
  },
})

const { lang, language, SiteUID } = gbCommonInfo

const abtSearchWords = {
  correctionRule: 'type=B', //纠错词
}

const oldAbt = {
  correctionRule: 'SearchSuggestwordFeedback',
  RelatedSearchNew: 'RelatedSearchNew',
}
const abtSearchPoskeyMap = {
  searchSuggestNew: 'SearchSuggestNew', // 联想词
  defaultWordsRule: 'SearchDefaultNew',
  hotWordsRule: 'SearchHotNew',
  PCAlltabCategory: 'PCAlltabCategory',
  SuggestUI: 'SuggestUI',
  SearchHotGD: 'SearchHotGD',
  HKTagSwitch: 'searchwordstyle',
  newpresearch: 'newpresearch',
  newpresearchlenovo: 'newpresearchlenovo',
  newpresearchicon: 'newpresearchicon',
  newStyle: 'newsearch' // 搜索框新样式：搜索框样式强化
}
// 底纹词曝光埋点用，参见需求wiki: pageId=1459831929
let exposeSearchTextArr = []

const abtSearchWordsRef = ref(abtSearchWords)

// abt展示新的预搜页联想词面板
const associationListNew = ref(false)
// abt控制是否展示底纹词 默认 tag
const shadedWordDefaultTagOn = ref(false)
// abt控制展示tag的联想词数量
const associationHKTagOn = ref(false)
// abt控制展示tag的联想词数量
const associationTagTotalRef = ref(0)
// abt控制展示的联想词的品类/属性筛选词数量
const associationAttrTotal = ref(0)
// 是否开始监听resize，重新insertAssociationAttrs
// const associationAttrResizing = ref(false)
// abt强化新预搜页联想词面板搜索词
const associationNewpresearchlenovo = ref(false)
// abt展示新预搜页联想词面板icon
const associationNewpresearchicon = ref(false)

const abtInfoRef = ref({
  SearchSuggestwordFeedback: '',
  SearchSuggestNew: null,
  SearchDefaultNew: null,
  SearchHotGD: null,
  SearchHotNew: null,
  PCAlltabCategory: '',
  RelatedSearchNew: '',
  searchwordstyle: null,
  newpresearch: null,
  newpresearchlenovo: null,
  newpresearchicon: null
})

const defaultSearchBox = ref(null)

const isBigSearch = computed(() => {
  const { PCAlltabCategory } = abtInfoRef.value
  return PCAlltabCategory?.p?.Alltab_head === 'showall&newhead&Enlargesearch'
})

const searchClass = computed(() => {
  const comp = getQueryString({ key: 'comp' })
  const srcModule = getQueryString( { key: 'src_module' })

  // is server render
  if (typeof window === 'undefined') {
    return {
      'search-enlarge-pro': props.isEnlargeSearchPro,
    }
  }

  const { RelatedSearchNew, newsearch } = abtInfoRef.value 
  const isEnlargeSearchPro = RelatedSearchNew?.p?.RelatedSearchLocNew === 'top'
  const searchInputNewStyle = newsearch?.param?.Listnewsearch === 'new'

  return {
    'hidden': srcModule === 'storeHomePage' && comp === 'shop-activity',
    'search-enlarge-pro': isBigSearch.value && isEnlargeSearchPro,
    'new-style': searchInputNewStyle
  }
})

const parseAbt = async () => {
  const posKeys = [...Object.values(abtSearchPoskeyMap), ...Object.values(oldAbt)]
  const abtInfo = await getUserAbtData()
  Object.keys(oldAbt).forEach(_ => {
    const posKey = oldAbt[_]
    abtInfo[posKey]?.param && (abtSearchWordsRef.value[_] = formatSearchModuleAbtParam(abtInfo[posKey]) || abtInfo[posKey].param)
  })
  const { sug_returnTag: associationTagTotal = '', display_attri = '0' } = abtInfo.SearchSuggestNew.p || {} // 联想词
  const { searchwordstylelx = '', } = abtInfo.searchwordstyle.p || {} // hk标签

  associationListNew.value = abtInfo.newpresearch?.p?.newpresearch === 'new'
  associationHKTagOn.value = searchwordstylelx === 'show'
  associationTagTotalRef.value = associationHKTagOn.value ? 99 :  associationTagTotal ? associationTagTotal - 0 : 0 
  associationAttrTotal.value = Number(display_attri)
  associationNewpresearchlenovo.value = abtInfo.newpresearchlenovo?.p?.newpresearchlenovo === 'B'
  associationNewpresearchicon.value = abtInfo.newpresearchicon?.p?.newpresearchicon === 'show'

  // sc
  posKeys.forEach((sceneStr) => {
    if (abtInfo[sceneStr] && Object.keys(abtInfo[sceneStr]).length > 0) {
      abtInfoRef.value[sceneStr] = abtInfo[sceneStr]
    }
  })
}

const handleExposeSearch = (payload) => {
  analysis.sa.searchInput('expose_search', payload, { Searchboxform: 2 })
}

const handleSlideExposeSearch = ({ defaultWords, index, currentWord, searchData } = {}) => {

  // 针对搜索框轮播上报（注意特殊case， 搜索结果页）
  if (!defaultWords[index]?.word || exposeSearchTextArr?.includes(defaultWords[index]?.word)) {
    return
  }

  analysis.sa.searchInput('expose_search', {
    currentWord: currentWord.guideObj
      ? currentWord.guideObj.keywords
      : currentWord.keywords,
    defaultWords: [searchData],
    localIndex: index,
  }, { Searchboxform: 2 })
  exposeSearchTextArr.push(defaultWords[index]?.word)

  sessionStorage.setItem(
    `${lang}/exposeSearchTextArr`,
    JSON.stringify(exposeSearchTextArr)
  )
}

const handleClearCurSearchWord = () => {
  analysis.sa.clickDelete()
}

const handleShadedWordDefaultTagOn = (payload) => {
  shadedWordDefaultTagOn.value = payload
}

const handleInputFocus = (payload = {}) => {
  const {
    activedMask,
    currentWord,
    searchData,
    associationNum,
    correctionStr,
    associationWords,
    historyWords,
    hotWords,
    hotImages,
    defaultWords,
    status,
  } = payload

  let searchMask = document.querySelector('.bsc-search-box__mask')
  if (searchMask) {
    searchMask.style.display = activedMask ? 'block' : 'none'
  }

  if (!activedMask) return

  const vm = {
    currentWord,
    placeholder: searchData,
    associationNum,
    correction: correctionStr,
    associationWords,
    historyWords,
    hotWords,
    hotImages,
    defaultWords,
    status,
    $nextTick: nextTick
  }

  analysis.doFocusAnalysis(vm) 
}

const handleExposeAssoc = (payload = {}) => {
  const {
    currentWord,
    correctionStr,
    associationWords,
  } = payload

  const vm = {
    associationWords,
    currentWord,
    correction: correctionStr,
  }

  analysis.exposeAssoc(vm)
}

const handleExposeAssocDebounce = debounce({
  func: handleExposeAssoc,
  wait: 2000,
})

const marketCallback = async (keyword) => {
  // 根据营销code获取专题标识符或跳转链接
  const data = await schttp({
    url: `/api/productList/urlByCode/get`,
    method: 'get',
    params: {
      code: keyword
    },
  })
  const { hrefTarget, hrefType } = data?.info || {}

  return {
    hrefTarget,
    hrefType,
  }
}

const exposeFromSessionStorage = () => {
  exposeSearchTextArr = JSON.parse(
    sessionStorage.getItem(`${lang}/exposeSearchTextArr`) || '[]'
  )
}

const getTrackData = ({ encodeKeyword, keyword, full_name, result_type, search_redir, position, originWord, tag, attr, wordTagType } = {}) => {
  const ana_keyword = encodeKeyword
  const ana_crowdId = keyword?.crowdId || 0
  const urlTrackData = analysis.search({
    keyword: ana_keyword,
    full_name: encodeURIComponent(full_name || '') || ana_keyword,
    result_type,
    search_redir,
    data: {
      position,
      type: keyword?.type,
      tag,
      attr
    },
    crowdId: ana_crowdId,
    originWord,
    wordTagType,
  })
  return urlTrackData
}

onMounted(() => {
  parseAbt()

  exposeFromSessionStorage()
})

</script>

<style lang="less" coped>
.bsc-search-box {
  flex-basis: 530px;
  max-width: 530px;

  &.hidden {
    display: none;
  }

  &.search-enlarge-pro {
    --bsc-search-box-max-width: 100%;
    flex-basis: 916px;
    max-width: 916px;
  }
}

</style>
