import { metricPageSuccess } from 'public/src/pages/common/business-monitor/common.js'
import HomeSlslog from 'public/src/pages/common/home_common/slslog.js'
import Monitor from 'public/src/pages/common/home_common/monitor.js'
import { abtservice } from 'public/src/services/abt'

export function useBCategory() {
  const pageStatus = status => {
    metricPageSuccess({
      page: 'page_category',
      status
    })
  }
  const noData = () => {
    HomeSlslog.slsCompFilter(
      {
        logLevel: 2,
        tag: 'HomeWarn',
        message: 'CategoryDataError',
        reason: 'tabEmpty',
        module: 'LeftBar',
        pageName: 'page_category'
      },
      false
    )
  }
  const clickCate = () => {
    Monitor.metricCategoryClick({
      tags: {
        page: 'page_category',
        cate_level: '2'
      },
      message: 'Right level second category click'
    })
  }
  const getAbTest = (posKeys) => {
    return abtservice?.getUserAbtResultForAnalysis(posKeys)
  }

  return {
    pageStatus,
    noData,
    clickCate,
    getAbTest
  }
}
