<template>
  <div
    class="common-header"
    :class="{
      'top-scroll-hide': !showAllHeader,
      'c-header j-c-header1': addHeaderClass,
      'no-banner': !topBannerConfig?.src
    }"
  >
    <BCommonHeader
      ref="commonHeaderRef"
      :prevent-scroll="preventScroll"
      :top-handle="topHandle"
      :up-handle="upHandle"
      :down-handle="downHandle"
      @show-common-header="showCommonHeader"
    >
      <template #content>
        <!-- banner -->
        <div
          class="common-header__banner"
        >
          <BTopBanner
            :top-banner-config="topBannerConfig"
          />
        </div>

        <!-- header -->
        <div class="common-header__content">
          <div class="bsc-search-box__mask"></div>
          <div
            :class="{
              'no-focus': noFocus
            }"
            @mousedown="headerMouseDown"
            @keydown="headerKeydown"
          >
            <div
              class="header-nav j-header-fixer"
              :class="{'topscroll-hide': !showAllHeader }"
            >
              <div class="common-header__wrap">
                <!-- logo -->
                <div class="common-header__wrap-left">
                  <BHeaderLogo
                    :is-ccc-skin-preview="isCccSkinPreview"
                    :ccc-skin-logo-img="cccSkinLogoImg"
                  />
                </div>
                <!-- search -->
                <div class="common-header__wrap-center">
                  <BSDefaultSearchBox
                    :language="language"
                    :is-enlarge-search-pro="isEnlargeSearchPro"
                  />
                </div>
    
                <div class="common-header__wrap-right">
                  <!-- user -->
                  <div class="header-icon">
                    <BPersonalCenter
                      :account-manager="accountManager"
                      :coupon-or-points-data="couponOrPointsData"
                      :un-read-num="unReadNum"
                      :is-msg-activity-and-promo-only="isMsgActivityAndPromoOnly"
                      @change-coupon-or-points-data="handleChangeCouponOrPointsData"
                      @change-activity-and-promo-only="handleActivityAndPromoOnly"
                      @change-unread-num="handleUnreadNum"
                      @init-coupon-or-points-notice-done="handleInitCouponOrPointsNoticeDone"
                    />
                  </div>
        
                  <!-- cart -->
                  <div
                    v-if="!isNewCartEntrance"
                    class="header-icon"
                  >
                    <BHeaderCart
                      class="page-common-header__cart"
                      mode="normal"
                    />
                  </div>
        
                  <!-- save -->
                  <div class="header-icon">
                    <BWishListEntry ref="wishListEntryRef" />
                  </div>
        
                  <!-- customer -->
                  <div class="header-icon">
                    <BHeaderRobot
                      @un-login-jump="unLoginJump"
                    />
                  </div>
        
                  <!-- language -->
                  <div class="header-icon">
                    <BSwichLangCurrency />
                  </div>
        
                  <!-- cart -->
                  <div
                    v-if="isNewCartEntrance"
                    class="header-icon"
                  >
                    <BHeaderCart
                      class="page-common-header__cart"
                      mode="incentive"
                    />
                  </div>
                </div>
              </div>
              <!-- category -->
              <div class="common-header__category">
                <BCategory 
                  :fs-data="cccNavData" 
                  :getabtest="getAbTest"
                  :all-cate-text="languageSsr?.SHEIN_KEY_PC_25688"
                  @no-data="noData"
                  @click-cate="clickCate"
                  @page-status="pageStatus"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </BCommonHeader>
  </div>
</template>

<script setup>
import { defineProps, computed, ref, onMounted } from 'vue'

// import bs components start
import { BCommonHeader } from '@shein-aidc/bs-common-header-laptop'
import { BHeaderRobot } from '@shein-aidc/bs-header-robot-laptop'
import { BSwichLangCurrency } from '@shein-aidc/bs-swich-lang-currency-laptop'
import { BHeaderCart } from '@shein-aidc/bs-header-cart-laptop'
import { BCategory } from '@shein-aidc/bs-category-laptop'
import { BTopBanner } from '@shein-aidc/bs-top-banner-laptop'
import { BHeaderLogo } from '@shein-aidc/bs-header-logo-laptop'
import { BPersonalCenter } from '@shein-aidc/bs-personal-center-laptop'
import { BWishListEntry } from '@shein-aidc/bs-wish-list-entry-laptop'
import BSDefaultSearchBox from './components/BSDefaultSearchBox.vue'
// import bs components end

// import hooks start
import { useCommonHeader } from './hooks/useCommonHeader'
import { useBCategory } from './hooks/useBCategory'
import { useCartTagTip } from './hooks/useCartTagTip.js'
import { usePersonalCenter } from './hooks/usePersonalCenter.js'
// import hooks end

// props start
const props = defineProps({
  context: {
    type: Object,
    default: () => ({})
  },
  addHeaderClass: {
    type: Boolean,
    default: true
  }
})
// props end

// const start
const isEnlargeSearchPro = props.context?.cccNav?.enlargeSearchPro || false
const isNewCartEntrance = props.context?.cccNav?.isNewCartEntrance || false
const topBannerConfig = props.context?.cccNav?.topBannerConfig || {}
const isCccSkinPreview = props.context?.isCccSkinPreview || false
const cccSkinLogoImg = props.context?.cccSkinLogoImg || ''
const { language = {} } = typeof gbCommonInfo == 'undefined' ? {} : gbCommonInfo
const languageSsr = props.context?.language || {}
const commonHeaderRef = ref(null)
const wishListEntryRef = ref(null)

// const end

// computed start
const cccNavData = computed(() => {
  const { lang, cccNav } = props?.context || {}
  return cccNav[lang] || []
}) 
// computed end


// use hooks start
const { showAllHeader, noFocus, showCommonHeader, headerMouseDown, headerKeydown } = useCommonHeader({ commonHeaderRef })
useCartTagTip({ isNewCartEntrance })
const { pageStatus, noData, clickCate, getAbTest } = useBCategory()
const { accountManager, couponOrPointsData, unReadNum, isMsgActivityAndPromoOnly, handleChangeCouponOrPointsData, handleActivityAndPromoOnly, handleUnreadNum, handleInitCouponOrPointsNoticeDone } = usePersonalCenter()
// use hooks end

/**  method start */
// 阻止滚动
const preventScroll = () => {
  // 设计师列表页不需要头部滚动
  if (location.pathname === '/designer_list') return true
  if (gbCommonInfo?.isActivity && window.dispatchByJs) {
    window.dispatchByJs = false
    return true
  }
  if (window.__noNeedNavScoll) return true
}

// 兼容主站某些页面的逻辑
const locationPathMatching = (pageList = []) => {
  const { pathname } = location
  return pageList.some((pagePath) => {
    return pathname.match(pagePath) !== null
  })
}
const topHandle = () => {
  let headerHeight = commonHeaderRef.value.offSetHeight
  let $easter_bunny = document.querySelector('.j-ebunny-game__top')
  let $order_review_category = document.querySelector('.j-order-review-category')
  if ($easter_bunny) {
    $easter_bunny.style.top = headerHeight
  }
  if ($order_review_category) {
    $order_review_category.classList.remove('order-review-category_pos-fixed')
    $order_review_category.classList.remove('order-review-category_pos-fixed0')
    $order_review_category.style.top = 0
  }
}
const downHandle = () => {
  let $easter_bunny = document.querySelector('.j-ebunny-game__top')
  let $order_review_category = document.querySelector('.j-order-review-category')
  if ($easter_bunny) {
    $easter_bunny.style.top = 0
  }
  if ($order_review_category) {
    $order_review_category.classList.add('order-review-category_pos-fixed')
    $order_review_category.classList.add('order-review-category_pos-fixed0')
    $order_review_category.style.top = 0
  }
}
const upHandle = () => {
  // 以下页面向上滚动不展示头部
  if (locationPathMatching([
    /^\/.+-store-(\d{10})\.html$/,
    '/store/home',
    '/global/marketing'
  ])) return true

  let headerHeight = commonHeaderRef.value.offSetHeight
  let $easter_bunny = document.querySelector('.j-ebunny-game__top')
  let $order_review_category = document.querySelector('.j-order-review-category')
  if ($easter_bunny) {
    $easter_bunny.style.top = headerHeight
  }
  if ($order_review_category) {
    $order_review_category.classList.add('order-review-category_pos-fixed')
    $order_review_category.classList.remove('order-review-category_pos-fixed0')
    $order_review_category.style.top = headerHeight
  }
}
// 兼容主站某些页面的逻辑

// 未登录客服打开登录弹窗
const unLoginJump = data => {
  import(/* webpackChunkName: "pre-online-help-module" */ '../pre_online_help').then(() => {
    preRobotLogin.loginModalShow(data?.no_login_second_confirm || false)
  })
}
// method end

onMounted(async() => {
  if (typeof window !== 'undefined') {
    window.fastwish = wishListEntryRef.value
  }
})
</script>

<style lang="less">
@all-header-height: 142px;
@no-banner-header-height: 94px;
@header-height: 56px;
.c-outermost-ctn {
  position: relative;
  min-height: 100%;
  padding-bottom: 458px;
}
.common-header {
  font-family: 'Muli', "Arial", "Helvetica", "sans-serif";
  [mir="rtl"] & {
    font-family: 'Cairo', "Arial", "Helvetica", "sans-serif";
  }
  height: @all-header-height;
  &.no-banner {
    height: @no-banner-header-height;
  }
  &.top-scroll-hide {
    .bsc-header-dropdown {
      transform: translateY(38px);
    }
  }
  &__banner {
    display: block;
  }
  &__wrap {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin: 0 auto;
    background-color: #fff;
    height: @header-height;
    border-bottom: none;

    // for mask
    z-index: 600;
    position: relative;
  }
  &__wrap-left {
    display: flex;
    height: @header-height;
    padding: 0 25px;
    align-items: center;
  }
  &__wrap-center {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: @header-height;
  }
  &__wrap-right {
    display: flex;
    box-sizing: border-box;
    padding-right: 24px;
    font-size: 12px;
    white-space: nowrap;
    padding-right: 24px;
    position: relative;
    height: @header-height;
    .header-icon {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      padding: 0 5px;
    }
  }

  .no-focus {
    /* stylelint-disable-next-line selector-max-specificity */
    a:focus, [tabindex]:focus {
      outline: 0;
    }
  }
  .bs-cate-type_img {
    img.lazyload {
      width: 100%;
      height: 100%;
      background-color: #eee;
    }
  }
  @media (min-width: 2560px) {
    .common-header__content, .common-header__banner {
      max-width: 2560px;
      padding: 0;
      margin: 0 auto;
    }
  }
}

.bsc-search-box__mask {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: calc(100vh + 94px);
  background-color: rgba(34,34,34,.3);
  z-index: 40;
}
</style>
